import React, { useState, useEffect } from 'react';
import { useRequest } from '../contexts/RequestContext'; // Import the custom hook
import '../styles/AnalyserPage.css'; 
import { IoIosHelpCircleOutline } from "react-icons/io";
import { PiFilePdfBold } from "react-icons/pi";
import AlertBox from './AlertBox';
import ConfirmBox from './ConfirmBox';
import '../styles/Modal.css';
import { API_BASE_URL } from '../constants'; 
import axios from 'axios'; 
import { HashLoader } from 'react-spinners'; 

const PageAnalyser = () => {
  const [analyserType, setAnalyserType] = useState('');
  const [isProceedEnabled, setIsProceedEnabled] = useState(false);
  const [files, setFiles] = useState([]); // Change to an array to hold multiple files
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const [downloadLink, setDownloadLink] = useState(''); // State for download link
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const [showCTA, setShowCTA] = useState(false); // State for showing CTA
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false); // New state for analysis completion
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const isDarkMode = document.body.classList.contains('dark-mode'); // Check for dark mode
  const { setRequestInProgress } = useRequest(); // Get the setter from context

  const handleChange = (event) => {
    const selectedType = event.target.value;
    setAnalyserType(selectedType);
    
    // Only enable proceed if a type is selected and files are present
    setIsProceedEnabled(selectedType !== '' && files.length > 0);
    
    // Move to the next step only if a type is selected
    if (selectedType !== '') {
        setCurrentStep(2); // Move to the next step
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    validateFiles(selectedFiles);
    
    // Move to the next step after files are validated
    if (selectedFiles.length > 0) {
        setCurrentStep(3); // Move to the submit step
    }
  };

  const validateFiles = (files) => {
    const allowedTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Only allow .docx
    const maxSize = 25 * 1024 * 1024; // 25 MB
    let validFiles = [];
    let errorMessages = [];

    files.forEach(file => {
      if (!allowedTypes.includes(file.type)) {
        errorMessages.push(`Invalid file type: ${file.name}. Please upload a .docx file.`);
      } else if (file.size > maxSize) {
        errorMessages.push(`File size exceeds 25 MB: ${file.name}. Please upload a smaller file.`);
      } else {
        validFiles.push(file);
      }
    });

    if (files.length > 1) {
        setErrorMessage('Please upload only one file.'); // Error message for multiple files
        setFiles([]); // Clear files if there are errors
        setIsProceedEnabled(false); // Disable proceed button
        return; // Exit the function
    }

    if (errorMessages.length > 0) {
      setErrorMessage(errorMessages.join(' '));
      setFiles([]); // Clear files if there are errors
      setIsProceedEnabled(false); // Disable proceed button
    } else {
      setErrorMessage(''); // Clear error message
      setFiles(prevFiles => [...prevFiles, ...validFiles]); // Store the valid files
      setIsProceedEnabled(validFiles.length > 0); // Enable proceed button if there are valid files
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    validateFiles(droppedFiles); // Validate dropped files
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior to allow drop
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove)); // Remove the selected file
    setIsProceedEnabled(files.length > 1); // Update proceed button state
  };

  const handleSubmit = () => {
    if (isLoading) return; // Prevent multiple submissions
    setShowConfirm(true);
    setConfirmMessage("Are you sure you want to submit for analysis?");
  };

  // New polling function to check task status
  const dependencyPollTaskStatus = (taskName) => {
    const interval = setInterval(() => {
        axios.get(`${API_BASE_URL}/sop-operations/get_task_state?task_name=${taskName}`, {
            headers: {
                'accept': 'application/json',
            },
        })
        .then(response => {
            const { status, downloadUrl } = response.data;
            if (status === "Completed") {
              setIsAnalysisComplete(true);
              setShowCTA(true);
              setIsLoading(false);
              setIsSubmitted(true); 
              setIsAnalysisComplete(true); 
              const downloadLink = downloadUrl; 
              if (downloadLink) {
                 setDownloadLink(downloadLink); 
              }
              setSuccessMessage('Analysis complete! Click below to download your document.'); 
              setShowCTA(true); 
              setCurrentStep(3); 
              clearInterval(interval);
            } else if (status === "In Progress") {
                // Continue polling
            } else {
              setErrorMessage('Please wait while your file is being processed. You can download the file from the Admin portal.');
              setIsAnalysisComplete(false);
              setIsSubmitted(false);
              setIsLoading(false);
              setShowCTA(false);
              setCurrentStep(1);
              setAnalyserType('');
              setIsProceedEnabled(false); 
              clearInterval(interval);
            }
        })
        .catch(error => {
            clearInterval(interval);
            setErrorMessage('Error checking task status. Please try again.'); // Handle error
        });
    }, 10000); // Poll every 10 seconds
  };

  const inconsistencyPollTaskStatus = (taskName) => {
    const interval = setInterval(() => {
      axios.get(`${API_BASE_URL}/sop-operations/get_task_state?task_name=${taskName}`, {
          headers: {
              'accept': 'application/json',
          },
      })
      .then(response => {
          const { status, downloadUrl } = response.data;
          if (status === "Completed") {
            clearInterval(interval);
            setIsAnalysisComplete(true);
            setShowCTA(true);
            setIsLoading(false);
            setIsSubmitted(true); 
            setIsAnalysisComplete(true); 
            const downloadLink = downloadUrl; 
            if (downloadLink) {
              setDownloadLink(downloadLink); 
            }
            setSuccessMessage('Analysis complete! Click below to download your document.'); // Success message
            setShowCTA(true); 
            setCurrentStep(3); 
            
          } else if (status === "In Progress") {
              // Continue polling
          } else {
            setErrorMessage('Please wait while your file is being processed. You can download the file from the Admin portal.');
            setIsAnalysisComplete(false);
            setIsSubmitted(false);
            setIsLoading(false);
            setShowCTA(false);
            setCurrentStep(1);
            setAnalyserType('');
            setIsProceedEnabled(false);
            clearInterval(interval);
          }
      })
      .catch(error => {
          clearInterval(interval);
          setErrorMessage('Error checking task status. Please try again.'); // Handle error
      });
  }, 10000); // Poll every 10 seconds
  };

  // Function to trigger file input click
  const triggerFileInput = () => {
    document.getElementById('file-upload').click();
  };

  const handleHelpClick = () => {
    setShowAlert(true);
    setAlertMessage("The Inconsistency Analyser checks for inconsistencies in the document, while the Dependency Analyser analyzes dependencies within the document.");
  };

  const generateTaskName = () => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // Format: YYYYMMDD
    const timeStamp = Date.now().toString().slice(-6); // Last 6 digits of timestamp
    const taskPrefix = analyserType === 'Dependency Analyser' ? 'Dependencies' : 'Inconsistencies';
    return `${taskPrefix}_analysis_${date}_${timeStamp}`;
  };

  useEffect(() => {
  }, [showAlert]);

  return (
    <div className="container-analyser">
      <h1 className="greeting text-center">Document Analyser</h1>
      <div className="progress-indicator">
        <div className={`step ${analyserType ? 'completed' : 'active'}`}>
          <span>{analyserType ? '✓' : '1'}</span> Select Analyser Type
        </div>
        <div className={`step ${currentStep > 1 && analyserType ? 'completed' : ''} ${currentStep === 2 ? 'active' : ''}`}>
          <span>{currentStep > 1 ? currentStep === 2 ? '2' : '✓' : '2'}</span> Upload Document
        </div>
        <div className={`step ${isSubmitted ? 'completed' : currentStep === 3 ? 'active' : ''}`}>
          <span>{isSubmitted ? '✓' : '3'}</span> Submit for Analysis
        </div>
      </div>

      <fieldset className="analyser-selection" disabled={isAnalysisComplete}>
        <legend>Select Analyser Type<span className="help-icon" title="The Inconsistency Analyser checks for inconsistencies in the document, while the Dependency Analyser analyzes dependencies within the document." onClick={handleHelpClick}>
          <IoIosHelpCircleOutline className="d-flex align-items-center" />
        </span> : 
        </legend>
        <div className="analyser-option">
          <input 
            type="radio" 
            id="inconsistency" 
            name="analyser" 
            value="Inconsistency Analyser" 
            checked={analyserType === 'Inconsistency Analyser'} 
            onChange={handleChange} 
            disabled={isAnalysisComplete} 
          />
          <label htmlFor="inconsistency">Inconsistency Analyser</label>
          <small className="tooltip">Identifies inconsistencies in the document.</small>
        </div>
        <div className="analyser-option">
          <input 
            type="radio" 
            id="dependency" 
            name="analyser" 
            value="Dependency Analyser" 
            checked={analyserType === 'Dependency Analyser'} 
            onChange={handleChange} 
            disabled={isAnalysisComplete} // Disable if analysis is complete
          />
          <label htmlFor="dependency">Dependency Analyser</label>
          <small className="tooltip">Analyzes dependencies within the document.</small>
        </div>
      </fieldset>

      {analyserType && (
        <div className="file-upload" onDrop={handleDrop} onDragOver={handleDragOver}>
          <legend htmlFor="file-upload">Upload Document (.docx):</legend>
          <div className={`drag-drop-area ${isAnalysisComplete ? 'disabled-drag-drop' : ''}`} onClick={triggerFileInput}>
            <input 
              type="file" 
              id="file-upload" 
              accept=".docx" 
              onChange={handleFileChange} 
              style={{ display: 'none' }} 
              disabled={isAnalysisComplete} 
            />
            <p>Drag and drop your file here, or click to select a file.</p>
          </div>
          <small id="file-upload-help" className="form-text text-muted">Please select a document to analyze.</small>
          {files.length > 0 && (
            <div className={`file-list ${isAnalysisComplete ? 'disabled' : ''}`}>
              <h5 className="p-2">Selected Files:</h5>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    {file.name} 
                    <button onClick={() => handleRemoveFile(file)} className="remove-button">Remove</button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}

      <button 
        onClick={handleSubmit} 
        disabled={!isProceedEnabled || files.length === 0 || isLoading || isAnalysisComplete} // Disable if analysis is complete
        className="proceed-button"
      >
        {isLoading ? 'Submitting...' : 'Submit for Analysis'}
      </button>

      {isLoading && (
        <div className="loading-spinner">
          <HashLoader 
            color={isDarkMode ? "#cccccc" : "#000000"} // Set color based on dark mode
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="p-2"
          /> {/* Use HashLoader instead of spinner */}
          <p className="spinner-text pt-3">
            {analyserType === 'Inconsistency Analyser' 
              ? 'Analyzing document for inconsistencies. This may take a few minutes. Please do not refresh the page or move to another page...' 
              : 'Analyzing document for dependencies. This may take a few minutes. Please do not refresh the page or move to another page...'}
          </p> 
        </div>
      )}

      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
          {downloadLink && (
            <a 
              href={downloadLink} 
              download={`analysed_${files.map(file => file.name).join('_')}`} 
              className="proceed-button"
              onClick={(e) => {
                  e.preventDefault(); 
                  setShowCTA(true); 
                  window.open(downloadLink, '_blank'); 
              }}
            >
              <PiFilePdfBold className="mr-3" /> Download Document
            </a>
          )}
        </div>
      )}

      {showCTA && (
        <div className="cta-message">
          <p>Would you like to analyze another document?</p>
          <button onClick={() => { 
              setFiles([]); 
              setShowCTA(false); 
              setSuccessMessage(''); 
              setErrorMessage(''); 
              setCurrentStep(1); 
              setAnalyserType(''); 
              setIsProceedEnabled(false); 
              setIsAnalysisComplete(false); 
              setIsSubmitted(false); 
          }}>
              Yes, analyze another document
          </button>
          <button onClick={() => setShowCTA(false)}>No, I'm done</button>
        </div>
      )}
    
    {showAlert && (
        <AlertBox 
            message={alertMessage}
            onClose={() => {
              setShowAlert(false);
            }} 
            theme={isDarkMode ? "dark" : "light"} // Set theme based on dark mode
        />
    )}

    {showConfirm && (
        <ConfirmBox 
            message={confirmMessage}
            onConfirm={() => {
                const formData = new FormData();
                setRequestInProgress(true);
                if (analyserType === 'Dependency Analyser') {
                    let taskName = generateTaskName();
                    formData.append('task_name', taskName); // Set task_name for Dependencies
                    files.forEach(file => {
                        formData.append('sop_document', file); // Append each file
                    });

                    // Update API call to use Axios
                    axios.post(`${API_BASE_URL}/sop-operations/dependencies`, formData, {
                        headers: {
                            'accept': 'application/json',
                        },
                    })
                    .then(response => {
                      setIsAnalysisComplete(true);
                      setShowCTA(true);
                      setIsLoading(false);
                      setIsSubmitted(true); 
                      setIsAnalysisComplete(true); 
                      const downloadLink = response.data.downloadUrl;
                      if (downloadLink) {
                        setDownloadLink(downloadLink); 
                      }
                      setSuccessMessage('Analysis complete! Click below to download your document.'); 
                      setShowCTA(true); 
                      setCurrentStep(3); 
                      setRequestInProgress(false);
                  })
                  .catch(error => {
                      dependencyPollTaskStatus(taskName);
                      setRequestInProgress(false);
                  });
                } else if (analyserType === 'Inconsistency Analyser') {
                    let taskName = generateTaskName();
                    formData.append('task_name', taskName); // Set task_name for Inconsistencies
                    files.forEach(file => {
                        formData.append('sop_document', file); // Append each file
                    });

                    axios.post(`${API_BASE_URL}/sop-operations/inconsistencies`, formData, {
                        headers: {
                            'accept': 'application/json',
                        },
                    })
                    .then(response => {
                        setIsAnalysisComplete(true);
                        setShowCTA(true);
                        setIsLoading(false);
                        setIsSubmitted(true); 
                        setIsAnalysisComplete(true); 
                        const downloadLink = response.data.downloadUrl; 
                        if (downloadLink) {
                          setDownloadLink(downloadLink); 
                        }
                        setSuccessMessage('Analysis complete! Click below to download your document.'); 
                        setShowCTA(true); 
                        setCurrentStep(3); 
                        setRequestInProgress(false);
                    })
                    .catch(error => {
                        inconsistencyPollTaskStatus(taskName);
                        setRequestInProgress(false);
                    });
                }
                setShowConfirm(false);
                setIsLoading(true);
                setSuccessMessage('');
                setErrorMessage('');  
            }} 
            onCancel={() => setShowConfirm(false)} 
            theme={isDarkMode ? "dark" : "light"}
        />
    )}


    </div>
  );
};

export default PageAnalyser;
